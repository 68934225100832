import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled, { keyframes } from "styled-components";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const windowHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrollPercent = (scrollTop / windowHeight) * 100;
    setScrollProgress(scrollPercent);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <FooterContainer>
      <ProgressBar progress={scrollProgress} />
      <FooterContent>
        <FooterLeft>
          <h3>Empower Workforce Solutions</h3>
          <p>Empowerment through excellence.</p>
          <p>Connecting job seekers with top companies.</p>
        </FooterLeft>

        <FooterCenter>
          <h4>Connect Us</h4>
          <p>
            <a
              href="tel:+919036110123"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <i className="fas fa-phone-alt"></i> +91 9036110123
            </a>
          </p>
          <SocialIcons>
            <SocialLink
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </SocialLink>
            <SocialLink
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </SocialLink>
            <SocialLink
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </SocialLink>
            <SocialLink
              href="https://www.linkedin.com/company/empower-workforce-solutions-pvt-ltd/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </SocialLink>
          </SocialIcons>
        </FooterCenter>

        <FooterRight>
          <h4>Email Us</h4>
          <FooterLinks>
            <p style={{ marginBottom: "0px" }}>
              <a
                href="mailto:career@empowerwfs.com"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <i className="fas fa-envelope"></i> career@empowerwfs.com
              </a>
            </p>
            <p style={{ marginTop: "0px" }}>
              <a
                href="mailto:sales@empowerwfs.com"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <i className="fas fa-envelope"></i> sales@empowerwfs.com
              </a>
            </p>
          </FooterLinks>
        </FooterRight>
      </FooterContent>
      <FooterBottom>
        <p>© All Copy rights reserved by Empower Workforce Solutions.</p>
      </FooterBottom>

      {/* WhatsApp Icon */}
      <WhatsAppIcon
        href="https://wa.me/919036110123"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Chat with us on WhatsApp"
      >
        <i className="fab fa-whatsapp"></i>
      </WhatsAppIcon>
    </FooterContainer>
  );
};

export default Footer;
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
  animation: ${fadeIn} 1s ease-in-out;
`;

const SocialLink = styled.a`
  color: #ffffff;
  font-size: 2rem;
  transition: transform 0.3s ease, color 0.3s ease;

  &:hover {
    transform: scale(1.2);
    color: #00adb5;
  }
`;

// above
const ProgressBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${({ progress }) => progress}%;
  height: 5px;
  background: linear-gradient(to right, #00adb5, #f39c12, #ff5733);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 0 0 20px rgba(255, 87, 51, 0.5);
  z-index: 1000;
  transition: width 0.1s ease-in-out, background 0.3s ease-in-out;
`;

const FooterContainer = styled.footer`
  background: #1f1f1f;
  color: #fff;
  padding: 10px 20px;
  margin-top: auto;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const FooterLeft = styled.div`
  flex: 1;
  padding: 10px;

  h3 {
    margin-bottom: 10px;
    font-size: 1.4rem;
    color: #f39c12;
  }

  p {
    margin: 5px 0;
    font-size: 0.9rem;
  }
`;

const FooterCenter = styled.div`
  flex: 1;
  padding: 10px;

  h4 {
    margin-bottom: 10px;
    font-size: 1.4rem;
    color: #f39c12;
  }

  p {
    margin: 5px 0;
    font-size: 0.9rem;

    i {
      margin-right: 10px;
    }
  }
`;

const FooterRight = styled.div`
  flex: 1;
  padding: 5px;

  h4 {
    margin-bottom: 5px;
    font-size: 1.4rem;
    color: #f39c12;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;

  a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    font-size: 0.9rem;

    &:hover {
      color: #f39c12;
    }
  }
`;

const FooterBottom = styled.div`
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid #444;

  p {
    font-size: 0.8rem;
    margin: 5px 0;
  }
`;

const WhatsAppIcon = styled.a`
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #25d366;
  color: white;
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
    background-color: #20c659;
  }

  @media (max-width: 768px) {
    bottom: 20px;
    right: 20px;
    padding: 12px;
    font-size: 22px;
  }
`;

import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

const ContactPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isInteracted, setIsInteracted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    lookingFor: "",
  });

  useEffect(() => {
    const hasVisited = sessionStorage.getItem("hasVisited");
    if (!hasVisited) {
      const popupTimer = setTimeout(() => {
        setIsOpen(true);
      }, 30000);

      const autoCloseTimer = setTimeout(() => {
        if (!isInteracted) {
          setIsOpen(false);
        }
      }, 35000);

      return () => {
        clearTimeout(popupTimer);
        clearTimeout(autoCloseTimer);
      };
    }
  }, [isInteracted]);

  const closePopup = () => {
    setIsOpen(false);
    if (isInteracted && isValidForm()) {
      sessionStorage.setItem("hasVisited", "true");
    }
  };

  const handleInteraction = () => {
    setIsInteracted(true);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const isValidForm = () => {
    const { name, phone, email, lookingFor } = formData;
    return (
      name.trim() !== "" &&
      phone.trim() !== "" &&
      email.trim() !== "" &&
      lookingFor.trim() !== ""
    );
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (isValidForm()) {
  //     try {
  //       console.log("Submitting form data:", formData);

  //       const response = await axios.post(
  //         "https://ews-server.onrender.com/api/contactpopup",
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       console.log("Response data:", response.data);

  //       if (response.data.success) {
  //         Toastify({
  //           text: "Form submitted successfully!",
  //           duration: 3000,
  //           gravity: "top",
  //           position: "right",
  //           style: {
  //             background: "#4CAF50",
  //           },
  //         }).showToast();
  //         closePopup();
  //       } else {
  //         throw new Error(response.data.message || "Error submitting form");
  //       }
  //     }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidForm()) {
      try {
        const response = await axios.post(
          "https://ews-server.onrender.com/api/contactpopup",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          setIsOpen(false); // Immediately close the popup
          sessionStorage.setItem("hasVisited", "true"); // Set session storage here
          Toastify({
            text: "Form submitted successfully!",
            duration: 3000,
            gravity: "top",
            position: "right",
            style: {
              background: "#4CAF50",
            },
          }).showToast();
        } else {
          throw new Error(response.data.message || "Error submitting form");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        Toastify({
          text:
            error.response?.data?.message ||
            error.message ||
            "An error occurred. Please try again later.",
          duration: 3000,
          gravity: "top",
          position: "right",
          style: {
            background: "#FF5733",
          },
        }).showToast();
      }
    } else {
      Toastify({
        text: "Please fill in all fields before submitting.",
        duration: 3000,
        gravity: "top",
        position: "right",
        style: {
          background: "#FF5733",
        },
      }).showToast();
    }
  };

  return (
    isOpen && (
      <PopupOverlay>
        <PopupContainer background="#2c3e50">
          <CloseButton onClick={closePopup}>×</CloseButton>
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
              onFocus={handleInteraction}
            />
            <Input
              type="tel"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              required
              onFocus={handleInteraction}
            />
            <Input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
              onFocus={handleInteraction}
            />
            <Textarea
              name="lookingFor"
              placeholder="Looking for..."
              value={formData.lookingFor}
              onChange={handleChange}
              required
              onFocus={handleInteraction}
            ></Textarea>
            <SubmitButton background="#d35400" type="submit">
              Submit
            </SubmitButton>
          </form>
        </PopupContainer>
      </PopupOverlay>
    )
  );
};

export default ContactPopup;

// Animations
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContainer = styled.div.attrs((props) => ({
  style: {
    background: props.background || "#1f1f1f", // Example of dynamic background if needed
  },
}))`
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: ${fadeIn} 0.5s ease-out;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: none;
  background: #333;
  color: #fff;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    background: #444;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: none;
  background: #333;
  color: #fff;
  resize: none;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    background: #444;
  }
`;

const SubmitButton = styled.button.attrs((props) => ({
  style: {
    background: props.background || "#f39c12", // Dynamic background if needed
  },
}))`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
  transition: background 0.3s ease;

  &:hover {
    background: #e67e22;
  }
`;

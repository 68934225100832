import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom"; // Use HashRouter here
import styled from "styled-components";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import GlobalStyles from "./GlobalStyles";
import ContactPopup from "./components/ContactPopup";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ScrollToTop from "./components/ScrolltoTop";

const App = () => {
  return (
    <>
      <GlobalStyles />
      <Router>
        <PageLayout>
          <Navbar />
          <ContentWrapper>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </ContentWrapper>
          <ContactPopup />
          <Footer />
        </PageLayout>
      </Router>
    </>
  );
};

export default App;

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  flex: 1;
`;

import React from "react";
import styled, { keyframes } from "styled-components";

const OurValues = () => {
  return (
    <ValuesSection>
      <Value>
        <IconWrapper color="#ff6f61">
          <i className="fas fa-balance-scale"></i>
        </IconWrapper>
        <ValueContent>
          <h3>Integrity</h3>
          <p>
            We uphold the highest standards of integrity in all of our actions.
          </p>
        </ValueContent>
      </Value>
      <Value>
        <IconWrapper color="#00adb5">
          <i className="fas fa-lightbulb"></i>
        </IconWrapper>
        <ValueContent>
          <h3>Innovation</h3>
          <p>
            We strive to develop innovative solutions that add value for our
            customers.
          </p>
        </ValueContent>
      </Value>
      <Value>
        <IconWrapper color="#f39c12">
          <i className="fas fa-users"></i>
        </IconWrapper>
        <ValueContent>
          <h3>Teamwork</h3>
          <p>
            We work together, across boundaries, to meet the needs of our
            customers.
          </p>
        </ValueContent>
      </Value>
    </ValuesSection>
  );
};

export default OurValues;

const IconPulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const ValuesSection = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 4rem 2rem;
  background-color: #000;
  color: #fff;
  text-align: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 1rem;
  }
`;

const Value = styled.div`
  flex: 1;
  max-width: 300px;
  margin: 0 1rem;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const IconWrapper = styled.div`
  font-size: 4rem;
  color: ${({ color }) => color};
  margin-bottom: 1.5rem;
  animation: ${IconPulse} 2s infinite;

  @media (max-width: 768px) {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
`;

const ValueContent = styled.div`
  h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: ${({ color }) => color};
    transition: color 0.3s ease;

    &:hover {
      color: #ff6f61;
    }
  }

  p {
    font-size: 1.2rem;
    color: #dddddd;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 1.5rem;
    }
  }
`;

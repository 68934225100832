import React, { useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import SEO from "./SEO";

import OurValues from "../components/OurValues";
import heroImage3 from "../assets/Designer.png"; // First Image
import heroImage1 from "../assets/img_2_empower-transformed.jpeg"; // Second Image
import heroImage2 from "../assets/empower-img.jpeg"; // Third Image
// import heroImage4 from "../assets/newLogo.png";

const Home = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [heroImage1, heroImage2, heroImage3];

  const stepRefs = useRef([]);
  useScrollAnimation(stepRefs);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>
      <SEO
        title="Home - Empower Workforce Solutions"
        description="Connecting job seekers with top companies. Empowerment through excellence."
      />
      <HomeContainer>
        <HeroSection backgroundImage={images[currentImageIndex]}>
          <HeroText>
            <h1>Empower Workforce Solutions</h1>
            <p>Empowerment through excellence</p>
          </HeroText>
        </HeroSection>
        <ContentSection>
          <h2>Connecting Talent with Opportunity</h2>
          <p>
            At Empower Workforce Solutions, we bridge the gap between job
            seekers and top companies. Our experienced team ensures that the
            right candidates meet the right employers, creating a synergy that
            benefits both parties.
          </p>
        </ContentSection>
        <PipeSection>
          <Pipe ref={(el) => (stepRefs.current[0] = el)}>
            <PipeContent align="left">
              <h3>Initial Consultation</h3>
              <p>We begin by understanding your unique needs.</p>
            </PipeContent>
            <PipeConnector align="left" />
          </Pipe>
          <Pipe ref={(el) => (stepRefs.current[1] = el)}>
            <PipeConnector align="right" />
            <PipeContent align="right">
              <h3>Talent Sourcing</h3>
              <p>Identifying top talent to meet your business goals.</p>
            </PipeContent>
          </Pipe>
          <Pipe ref={(el) => (stepRefs.current[2] = el)}>
            <PipeContent align="left">
              <h3>Screening Process</h3>
              <p>Rigorous evaluation to ensure the best fit.</p>
            </PipeContent>
            <PipeConnector align="left" />
          </Pipe>
          <Pipe ref={(el) => (stepRefs.current[3] = el)}>
            <PipeConnector align="right" />
            <PipeContent align="right">
              <h3>Interview & Placement</h3>
              <p>Seamlessly connecting candidates with employers.</p>
            </PipeContent>
          </Pipe>
          <Pipe ref={(el) => (stepRefs.current[4] = el)}>
            <PipeContent align="left">
              <h3>Ongoing Support</h3>
              <p>Continuous support to ensure long-term success.</p>
            </PipeContent>
            <PipeConnector align="left" />
          </Pipe>
        </PipeSection>

        <OurValues />
      </HomeContainer>
    </>
  );
};

export default Home;

// Scroll Animation Hook
const useScrollAnimation = (elementRefs) => {
  useEffect(() => {
    const handleScroll = () => {
      elementRefs.current.forEach((ref) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            ref.classList.add("visible");
          } else {
            ref.classList.remove("visible");
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [elementRefs]);
};

// Styled Components and Animations
const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeroSection = styled.section`
  position: relative;
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  perspective: 1000px;

  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #000000, #000000);
    opacity: 0.5;
  }
`;

const text3D = keyframes`
  0% {
   
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5),
                 0 0 20px rgba(255, 255, 255, 0.3);
  }
  50% {
   
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.7),
                 0 0 40px rgba(255, 255, 255, 0.5);
  }
  100% {
    
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5),
                 0 0 20px rgba(255, 255, 255, 0.3);
  }
`;

const HeroText = styled.div`
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  position: relative;
  z-index: 1;
  transform-style: preserve-3d;

  h1 {
    font-size: 4rem;
    margin-bottom: 1rem;
    font-weight: 700;
    letter-spacing: 2px;
    animation: ${text3D} 5s infinite alternate ease-in-out;
  }

  p {
    font-size: 1.7rem;
    margin-top: 0.5rem;
    font-weight: 300;
    animation: ${text3D} 5s infinite alternate ease-in-out;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.2rem;
    }
  }
`;

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ContentSection = styled.section`
  padding: 4rem 2rem;
  text-align: center;
  animation: ${fadeInUp} 1.5s ease-out;

  h2 {
    margin-bottom: 1rem;
    font-size: 2.5rem;
    font-family: "Roboto", sans-serif;
    color: #00adb5;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  p {
    font-size: 1.3rem;
    max-width: 800px;
    margin: 0 auto;
    font-family: "Roboto", sans-serif;
    color: #dddddd;
    line-height: 1.6;
    margin-top: 1rem;
    animation: ${fadeInUp} 2s ease-out;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

const slideInFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideInFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const PipeSection = styled.section`
  width: 100%;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;

  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 100%;
    background: linear-gradient(#00adb5, #ffffff, #00adb5); /* Central Pipe */
  }
`;

const PipeContent = styled.div`
  background-color: #1a1a1a;
  padding: 1.5rem 2rem;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.5);
  position: relative;
  z-index: 1;
  width: 45%;
  text-align: center;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  h3 {
    font-size: 1.5rem;
    color: #ffffff;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    color: #dddddd;
  }

  @media (max-width: 768px) {
    width: 90%;

    h3 {
      font-size: 1.3rem;
    }

    p {
      font-size: 0.9rem;
    }
  }
`;

const PipeConnector = styled.div`
  position: absolute;
  width: 50%;
  height: 4px;
  background: #00adb5;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s ease;

  ${({ align }) =>
    align === "left" &&
    `
    right: 50%;
  `}

  ${({ align }) =>
    align === "right" &&
    `
    left: 50%;
  `}

  @media (max-width: 768px) {
    width: 20%;
  }
`;

const Pipe = styled.div`
  width: 100%;
  max-width: 800px;
  position: relative;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;

  &:nth-child(even) {
    justify-content: flex-end;
    ${PipeContent} {
      animation: ${slideInFromRight} 1s ease-out forwards;
    }
  }

  &:nth-child(odd) {
    justify-content: flex-start;
    ${PipeContent} {
      animation: ${slideInFromLeft} 1s ease-out forwards;
    }
  }

  &:hover {
    ${PipeContent} {
      background-color: #333;
      box-shadow: 0 0 20px rgba(25, 11, 95, 1);
    }
  }
`;

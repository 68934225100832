import React, { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import SEO from "./SEO";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

// Theme definition
const theme = {
  colors: {
    primary: "#00adb5",
    background: "#121212", // Dark background for a sleek look
    text: "#ffffff",
    cardBackground: "#1e1e1e",
    hoverBackground: "#00adb5",
    accent: "#00adb5",
    black: "#000",
  },
  fonts: {
    heading: "'Poppins', sans-serif",
    body: "'Open Sans', sans-serif",
  },
};

const Services = () => {
  const { ref: statsRef, inView: statsInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <ThemeProvider theme={theme}>
      <SEO
        title="Services - Empower Workforce Solutions"
        description="Discover the services offered by Empower Workforce Solutions, connecting job seekers with top companies."
      />
      <HeroSection>
        <HeroContent>
          <motion.h1
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Your Gateway to Talent & Opportunity
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            We bridge the gap between skilled professionals and thriving
            businesses.
          </motion.p>
        </HeroContent>
      </HeroSection>
      <ServicesContainer>
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Our Services
        </motion.h2>
        <ServiceList>
          {serviceData.map((service, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1, duration: 0.5 }}
            >
              <Service>
                <ServiceContent>
                  <ServiceIcon>
                    <i className={service.icon}></i>
                  </ServiceIcon>
                  <h3>{service.title}</h3>
                </ServiceContent>
                <ServiceHover>
                  <p>{service.description}</p>
                </ServiceHover>
              </Service>
            </motion.div>
          ))}
        </ServiceList>
      </ServicesContainer>
      <StatsSection ref={statsRef}>
        <StatsContainer>
          <StatItem>
            <StatNumber
              initial={{ opacity: 0, y: 20 }}
              animate={
                statsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
              }
              transition={{ duration: 0.7, delay: 0.2 }}
            >
              500+
            </StatNumber>
            <StatDescription
              initial={{ opacity: 0, y: 20 }}
              animate={
                statsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
              }
              transition={{ duration: 0.7, delay: 0.4 }}
            >
              Successful Placements
            </StatDescription>
          </StatItem>
          <StatItem>
            <StatNumber
              initial={{ opacity: 0, y: 20 }}
              animate={
                statsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
              }
              transition={{ duration: 0.7, delay: 0.6 }}
            >
              50+
            </StatNumber>
            <StatDescription
              initial={{ opacity: 0, y: 20 }}
              animate={
                statsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
              }
              transition={{ duration: 0.7, delay: 0.8 }}
            >
              Clients Worldwide
            </StatDescription>
          </StatItem>
          <StatItem>
            <StatNumber
              initial={{ opacity: 0, y: 20 }}
              animate={
                statsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
              }
              transition={{ duration: 0.7, delay: 1.0 }}
            >
              100%
            </StatNumber>
            <StatDescription
              initial={{ opacity: 0, y: 20 }}
              animate={
                statsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
              }
              transition={{ duration: 0.7, delay: 1.2 }}
            >
              Client Satisfaction Rate
            </StatDescription>
          </StatItem>
        </StatsContainer>
      </StatsSection>
      <TestimonialsSection>
        <h2>Our Success Stories</h2>
        <TestimonialCarousel />
      </TestimonialsSection>
      <ContactSection>
        <h2>Get in Touch</h2>
        <p>
          Ready to take the next step? Whether you’re a job seeker or a company
          looking for talent, we’re here to help. Contact us today!
        </p>
        <ContactButton to="/contact">Contact Us</ContactButton>
      </ContactSection>
    </ThemeProvider>
  );
};

export default Services;

// Additional Sections

const HeroSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #000428, #121212);
  color: ${(props) => props.theme.colors.text};
  padding: 5rem 2rem;
  text-align: center;
  background-size: cover;
  background-position: center;

  h1 {
    font-size: 3.5rem;
    font-family: ${(props) => props.theme.fonts.heading};
  }

  p {
    font-size: 1.5rem;
    font-family: ${(props) => props.theme.fonts.body};
    margin-top: 1rem;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.2rem;
    }
  }
`;

const HeroContent = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors.text};

  h1 {
    font-size: 3rem;
    font-family: ${(props) => props.theme.fonts.heading};
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.5rem;
    font-family: ${(props) => props.theme.fonts.body};
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.2rem;
    }
  }
`;

const TestimonialsSection = styled.div`
  padding: 4rem 2rem;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  text-align: center;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-family: ${(props) => props.theme.fonts.heading};
  }
`;

// Enhanced Carousel Component for Testimonials
const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonialCount = testimonialData.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === testimonialCount - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change testimonial every 5 seconds

    return () => clearInterval(interval);
  }, [testimonialCount]);

  return (
    <CarouselContainer>
      <CarouselInner currentIndex={currentIndex}>
        {testimonialData.map((testimonial, index) => (
          <Testimonial key={index}>
            <p>"{testimonial.quote}"</p>
            <h4>– {testimonial.author}</h4>
          </Testimonial>
        ))}
      </CarouselInner>
    </CarouselContainer>
  );
};

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const CarouselInner = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${(props) => props.currentIndex * -100}%);
`;

const Testimonial = styled.div`
  flex: 0 0 100%;
  box-sizing: border-box;
  padding: 2rem;
  background: ${(props) => props.theme.colors.cardBackground};
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);

  p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
    font-family: ${(props) => props.theme.fonts.body};
  }

  h4 {
    font-size: 1.2rem;
    color: ${(props) => props.theme.colors.primary};
    font-family: ${(props) => props.theme.fonts.heading};
  }
`;

const StatsSection = styled.div`
  padding: 4rem 2rem;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  text-align: center;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-family: ${(props) => props.theme.fonts.heading};
  }
`;

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const StatItem = styled.div`
  text-align: center;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-family: ${(props) => props.theme.fonts.heading};
  }
`;

const StatNumber = styled(motion.p)`
  font-size: 3rem;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.heading};
  margin-bottom: 0.5rem;
`;

const StatDescription = styled(motion.p)`
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.body};
`;

const ContactSection = styled.div`
  padding: 4rem 2rem;
  background-color: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.text};
  text-align: center;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-family: ${(props) => props.theme.fonts.heading};
  }

  p {
    font-size: 1.5rem;
    font-family: ${(props) => props.theme.fonts.body};
    margin-bottom: 2rem;
  }
`;

const ContactButton = styled(Link)`
  padding: 1rem 2rem;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.text};
  border: none;
  border-radius: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  text-decoration: none;
  font-family: ${(props) => props.theme.fonts.heading};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.text};
    color: ${(props) => props.theme.colors.primary};
  }
`;

// Main Services Section
const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  min-height: 100vh;
  padding: 4rem 2rem;
  text-align: center;

  h2 {
    margin-bottom: 3rem;
    font-size: 2.5rem;
    color: ${(props) => props.theme.colors.primary};
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
    display: inline-block;
    padding-bottom: 0.5rem;
    font-family: ${(props) => props.theme.fonts.heading};

    &::after {
      content: "";
      position: absolute;
      width: 50px;
      height: 4px;
      background: ${(props) => props.theme.colors.primary};
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 2rem;
    }
  }
`;

const ServiceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const ServiceHover = styled.div`
  background: rgba(0, 0, 0, 0.95);
  padding: 2rem;
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.4s ease, transform 0.4s ease;

  p {
    color: ${(props) => props.theme.colors.hoverBackground};
    font-size: 1.1rem;
    line-height: 1.6;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

    &:hover {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Service = styled.div`
  background: ${(props) => props.theme.colors.cardBackground};
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: transform 0.4s ease, box-shadow 0.4s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
  }

  &:hover ${ServiceHover} {
    opacity: 1;
    transform: scale(1);
  }

  @media (max-width: 768px) {
    &:hover {
      transform: none;
    }
  }
`;

const ServiceContent = styled.div`
  padding: 2rem;
  text-align: center;
  z-index: 2;

  h3 {
    margin-top: 1rem;
    font-size: 1.5rem;
    color: ${(props) => props.theme.colors.primary};
    font-family: ${(props) => props.theme.fonts.heading};
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 1.2rem;
    }
  }
`;

const ServiceIcon = styled.div`
  font-size: 3rem;
  color: ${(props) => props.theme.colors.primary};

  i {
    transition: transform 0.3s;
  }

  &:hover i {
    transform: scale(1.2);
  }
`;

// Data for services
const serviceData = [
  {
    icon: "fas fa-user-check",
    title: "Candidate Screening",
    description:
      "We rigorously assess and verify candidates to ensure the best fit for your company.",
  },
  {
    icon: "fas fa-handshake",
    title: "Corporate Partnerships",
    description:
      "Partner with industry leaders to secure top-tier talent tailored to your needs.",
  },
  {
    icon: "fas fa-sync-alt",
    title: "Continuous Support",
    description:
      "We offer ongoing support post-placement to ensure long-term success and satisfaction.",
  },
  {
    icon: "fas fa-chart-line",
    title: "Market Insights",
    description:
      "Stay ahead with our in-depth market analysis and talent acquisition strategies.",
  },
];

// Data for testimonials
const testimonialData = [
  {
    quote:
      "Their dedication and expertise made all the difference in our hiring process. We couldn't be happier.",
    author: "Sainath Reddy, Founder&Director at FINTIPS Consultants",
  },
  {
    quote:
      "Empower Workforce Solutions helped us find the right talent quickly, and the entire process was seamless.",
    author: "Veeranjaneyulu, CEO of Anji Associates",
  },
  {
    quote:
      "The support provided by this company is extraordinary. They helped us scale our team effectively and maintain high standards.",
    author: "Raju Kumar, CTO of Tech Innovators",
  },
  {
    quote:
      "Working with this agency has been a smooth process. Their attention to our needs and follow-up has been incredibly helpful.",
    author: "Revanth Reddy, HR Head at Creative Solutions",
  },
  {
    quote:
      "The team not only provided us with great candidates but also ensured they were well-prepared and a good cultural fit.",
    author: "Kavitha, COO of Financial Corp",
  },
];

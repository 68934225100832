import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta
      name="keywords"
      content="Empower Workforce Solutions, job seekers, recruitment, employment, staffing"
    />
    <meta name="author" content="Empower Workforce Solutions" />
  </Helmet>
);

export default SEO;

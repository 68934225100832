import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Lottie from "lottie-react";
import walkingManAnimation from "../assets/walkingManAnimation.json"; // replace with the correct path to your animation JSON file

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <Nav>
      <Logo>
        <Link to="/" onClick={closeMenu}>
          <StyledImage src={require("../assets/newLogo.png")} alt="Your Logo" />
          <StyledAnimation>
            <Lottie
              animationData={walkingManAnimation}
              loop={true}
              style={{ width: "100px", height: "70px" }} // Adjust width and height as needed
            />
          </StyledAnimation>
        </Link>
      </Logo>
      <Hamburger onClick={toggleMenu}>
        <span />
        <span />
        <span />
      </Hamburger>

      <Menu isOpen={isOpen}>
        <NavItem>
          <StyledLink to="/" onClick={closeMenu}>
            Home
          </StyledLink>
        </NavItem>
        <NavItem>
          <StyledLink to="/about" onClick={closeMenu}>
            About
          </StyledLink>
        </NavItem>
        <NavItem>
          <StyledLink to="/services" onClick={closeMenu}>
            Services
          </StyledLink>
        </NavItem>
        <NavItem>
          <StyledLink to="/contact" onClick={closeMenu}>
            Contact
          </StyledLink>
        </NavItem>
      </Menu>
    </Nav>
  );
};

export default Navbar;

const StyledImage = styled.img`
  width: 60px; /* Adjust the size of the image as needed */
  height: 50px;
  transition: transform 0.3s ease-in-out;
  border-radius: 70%;

  &:hover {
    transform: scale(1.1); /* Slight zoom on hover */
  }

  @media (max-width: 768px) {
    width: 40px; /* Adjust size for smaller screens */
    height: 40px;
  }
`;

const StyledAnimation = styled.div`
  margin-left: 10px; /* Adds space between the image and the animation */
`;

const Nav = styled.nav`
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1f1f1f;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; /* Ensure there's space between image and animation */

  a {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;

  span {
    width: 25px;
    height: 3px;
    background: #f39c12;
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const Menu = styled.ul`
  display: flex;
  gap: 20px;
  list-style: none;

  @media (max-width: 768px) {
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 0;
    background: #1f1f1f;
    width: 100%;
    padding: 20px;
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  }
`;

const NavItem = styled.li`
  margin: 0;
`;

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
  padding: 8px 16px;
  border-radius: 5px;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: #f39c12;
    color: #1f1f1f;
  }
`;

import React from "react";
import styled, { keyframes } from "styled-components";
import SEO from "./SEO";
import AnimatedTimeline from "../components/EnhancedTimeline";
import {
  FaBullseye,
  FaFlag,
  FaUsers,
  FaHandsHelping,
  FaCogs,
} from "react-icons/fa";

const About = () => {
  return (
    <>
      <SEO
        title="About - Empower Workforce Solutions"
        description="Learn more about Empower Workforce Solutions and how we connect talent with top companies."
      />
      <PageWrapper>
        <AboutContainer>
          <SectionWrapper>
            <Section>
              <Card>
                <CardInner>
                  <CardFront>
                    <IconWrapper>
                      <FaBullseye />
                    </IconWrapper>
                    <h2 style={{ color: "green" }}>About Us</h2>
                    <p>
                      Empower Workforce Solutions was founded with the mission
                      of connecting qualified candidates with exceptional
                      companies. We pride ourselves on our meticulous screening
                      process, ensuring that every job seeker we represent meets
                      the highest standards.
                    </p>
                  </CardFront>
                  <CardBack>
                    <h2>Our Goal</h2>
                    <p>
                      To become the top workforce solutions provider, recognized
                      for connecting the best candidates with the best
                      companies.
                    </p>
                  </CardBack>
                </CardInner>
              </Card>
            </Section>

            <Section>
              <Card>
                <CardInner>
                  <CardFront>
                    <IconWrapper>
                      <FaFlag />
                    </IconWrapper>
                    <h2 style={{ color: "green" }}>Our Mission</h2>
                    <p>
                      Our mission is to empower individuals by providing them
                      with opportunities that align with their skills and
                      aspirations.
                    </p>
                  </CardFront>
                  <CardBack>
                    <h2>Our Approach</h2>
                    <p>
                      We ensure that our clients receive the best candidates and
                      our candidates find fulfilling careers.
                    </p>
                  </CardBack>
                </CardInner>
              </Card>
            </Section>

            <Section>
              <Card>
                <CardInner>
                  <CardFront>
                    <IconWrapper>
                      <FaCogs />
                    </IconWrapper>
                    <h2 style={{ color: "green" }}>Our Vision</h2>
                    <p>
                      To become the leading workforce solutions provider,
                      recognized for our commitment to excellence, integrity,
                      and innovation.
                    </p>
                  </CardFront>
                  <CardBack>
                    <h2>Innovation</h2>
                    <p>
                      We aim to set the standard for quality in recruitment and
                      workforce development.
                    </p>
                  </CardBack>
                </CardInner>
              </Card>
            </Section>

            <Section>
              <Card>
                <CardInner>
                  <CardFront>
                    <IconWrapper>
                      <FaHandsHelping />
                    </IconWrapper>
                    <h2 style={{ color: "green" }}>Our Values</h2>
                    <ValueList>
                      <li>Integrity</li>
                      <li>Excellence</li>
                      <li>Innovation</li>
                      <li>Respect</li>
                      <li>Collaboration</li>
                    </ValueList>
                  </CardFront>
                  <CardBack>
                    <h2>Our Principles</h2>
                    <p>
                      We operate with honesty, transparency, and respect,
                      valuing diversity and inclusivity.
                    </p>
                  </CardBack>
                </CardInner>
              </Card>
            </Section>

            <Section>
              <Card>
                <CardInner>
                  <CardFront>
                    <IconWrapper>
                      <FaUsers />
                    </IconWrapper>
                    <h2 style={{ color: "green" }}>Our Team</h2>
                    <p>
                      Our team is composed of experienced professionals
                      passionate about connecting talent with opportunity.
                    </p>
                  </CardFront>
                  <CardBack>
                    <h2>Teamwork</h2>
                    <p>
                      We believe in the power of teamwork and partnership to
                      achieve common goals.
                    </p>
                  </CardBack>
                </CardInner>
              </Card>
            </Section>
          </SectionWrapper>
        </AboutContainer>
        <AnimatedTimeline />
      </PageWrapper>
    </>
  );
};

export default About;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const AboutContainer = styled.div`
  flex: 1;
  padding: 4rem 2rem;
  background-color: #000000; /* Black background */
  color: #ffffff; /* White text for contrast */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  max-width: 1200px;
`;

const Section = styled.div`
  width: 30%; /* Reduced width to make the cards smaller */
  min-width: 250px; /* Decreased minimum width */
  animation: ${fadeIn} 1s ease-out forwards;

  @media (max-width: 768px) {
    width: 100%; /* Adjusted for smaller screens */
    padding: 1rem;
  }
`;

const Card = styled.div`
  perspective: 1000px; /* Perspective for 3D effect */
  width: 100%;
`;

const CardInner = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%; /* Maintain aspect ratio for square cards */
  transform-style: preserve-3d;
  transition: transform 0.6s ease; /* Smoother and faster transition */

  ${Card}:hover & {
    transform: rotateY(180deg); /* Flip the card on hover */
  }
`;

const CardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: #1f1f1f;
  color: #ffffff;
  border-radius: 15px;
  padding: 1.5rem; /* Reduced padding to make cards more compact */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    padding: 1rem;
    h2 {
      font-size: 1.2rem;
    }
    p {
      font-size: 0.9rem;
    }
  }
`;

const CardBack = styled(CardFront)`
  transform: rotateY(180deg); /* Position the back side */
  background: #00adb5; /* Teal background for the back */
  color: #000000; /* Black text for contrast */

  @media (max-width: 768px) {
    padding: 1rem;
    h2 {
      font-size: 1.2rem;
    }
    p {
      font-size: 0.9rem;
    }
  }
`;

const IconWrapper = styled.div`
  font-size: 2.5rem; /* Reduced icon size */
  color: #00adb5; /* Teal color for icons */
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const ValueList = styled.ul`
  list-style-type: none;
  padding: 0;
  font-size: 1.1rem; /* Slightly smaller text */
  line-height: 1.6;
  color: #dddddd;

  li {
    margin-bottom: 0.5rem;
    position: relative;
    padding-left: 1.5rem;

    &::before {
      content: "✔";
      position: absolute;
      left: 0;
      top: 0;
      color: #00adb5; /* Teal checkmark */
      font-size: 1.5rem;
    }
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

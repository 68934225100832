import React, { useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";

const EnhancedTimeline = () => {
  const stepRefs = useRef([]);

  useScrollAnimation(stepRefs);

  return (
    <TimelineContainer>
      <TimelineTitle>Our Company Journey</TimelineTitle>
      <TimelineSteps>
        <TimelineStep ref={(el) => (stepRefs.current[0] = el)}>
          <StepIcon>
            <i className="fas fa-rocket"></i>
          </StepIcon>
          <StepContent>
            <h3>Founded in 2024</h3>
            <p>We started with a mission to innovate and inspire.</p>
          </StepContent>
        </TimelineStep>
        <TimelineStep ref={(el) => (stepRefs.current[1] = el)}>
          <StepIcon>
            <i className="fas fa-chart-line"></i>
          </StepIcon>
          <StepContent>
            <h3>Growth & Expansion</h3>
            <p>We expanded globally, reaching new markets and clients.</p>
          </StepContent>
        </TimelineStep>
        <TimelineStep ref={(el) => (stepRefs.current[2] = el)}>
          <StepIcon>
            <i className="fas fa-lightbulb"></i>
          </StepIcon>
          <StepContent>
            <h3>Innovation</h3>
            <p>Pioneering new solutions that drive progress and efficiency.</p>
          </StepContent>
        </TimelineStep>
        <TimelineStep ref={(el) => (stepRefs.current[3] = el)}>
          <StepIcon>
            <i className="fas fa-forward"></i>
          </StepIcon>
          <StepContent>
            <h3>Looking Ahead</h3>
            <p>We continue to set new benchmarks in the industry.</p>
          </StepContent>
        </TimelineStep>
      </TimelineSteps>
    </TimelineContainer>
  );
};

export default EnhancedTimeline;

// Scroll Animation Hook
const useScrollAnimation = (elementRefs) => {
  useEffect(() => {
    const handleScroll = () => {
      elementRefs.current.forEach((ref) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            ref.classList.add("visible");
          } else {
            ref.classList.remove("visible");
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [elementRefs]);
};

// Styled Components and Animations

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const TimelineContainer = styled.div`
  padding: 5rem 2rem;
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-top: 3rem;
`;

const TimelineTitle = styled.h2`
  font-size: 3rem;
  color: #00adb5;
  margin-bottom: 3rem;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  animation: ${fadeIn} 1s ease-out;
`;

const TimelineSteps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
`;

const TimelineStep = styled.div`
  width: 80%;
  padding: 2rem;
  background-color: #1a1a1a;
  border-radius: 10px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const StepIcon = styled.div`
  font-size: 2.5rem;
  color: #00adb5;
  margin-right: 2rem;
  background-color: #1f1f1f;
  padding: 1rem;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  &:nth-child(even) {
    margin-left: 2rem;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

const StepContent = styled.div`
  flex: 1;

  h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #00adb5;
  }

  p {
    font-size: 1.2rem;
    color: #dddddd;
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }

  html, body {
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal overflow */
    background-color: #000000;
    color: #333;
    line-height: 1.6;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 1.5rem 0 1rem;
    line-height: 1.25;
  }

  p {
    margin-bottom: 1.25rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 1.8rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

export default GlobalStyles;

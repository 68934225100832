import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import SEO from "./SEO";
import { AiOutlineCheckCircle } from "react-icons/ai";
import LoadingSpinner from "../components/LoadingSpinner";

// Define styled components here
const ContactContainer = styled.div`
  padding: 2rem;
  text-align: center;

  h2 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: #f39c12;
    font-weight: 700;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 2rem;
    }
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 600px;
  margin: 0 auto;
  background-color: #1f1f1f;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  input,
  textarea {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    background-color: #333;
    color: #fff;
  }

  input::placeholder,
  textarea::placeholder {
    color: #bbb;
  }

  input:focus,
  textarea:focus {
    outline: none;
    border-color: #f39c12;
  }
`;

const ErrorMessage = styled.span`
  color: #ff4d4f;
  font-size: 0.875rem;
`;

const Button = styled.button`
  padding: 0.75rem;
  background: #f39c12;
  color: #1f1f1f;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: #fff;
    color: #333;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ResumeLabel = styled.label`
  font-size: 1.2rem;
  color: #f39c12;
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: inline-block;
  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
`;
const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #f39c12;
  gap: 1rem;

  p {
    font-size: 1.2rem;
  }
`;

const SubmissionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #f39c12;
  font-size: 1.2rem;
  text-align: center;

  p {
    margin: 0;
  }
`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    resume: null, // Add resume to the form data
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files ? files[0] : value, // Handle file input
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    // Name validation: required and only letters
    if (!formData.name) {
      newErrors.name = "Name is required";
    } else if (!/^[a-zA-Z\s]+$/.test(formData.name)) {
      newErrors.name = "Name can only contain letters and spaces";
    }

    // Email validation: required and valid format
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }

    // Phone number validation: required and must be 10 digits
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Phone number must be 10 digits";
    }

    // Message validation: required
    if (!formData.message) {
      newErrors.message = "Message is required";
    }

    // Resume validation: required
    if (!formData.resume) {
      newErrors.resume = "Resume is required";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validation
    const formErrors = validateForm();

    // If there are errors, set them and stop form submission
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    // Clear errors before submitting
    setErrors({});

    setIsSubmitting(true);
    try {
      const data = new FormData();
      data.append("name", formData.name);
      data.append("email", formData.email);
      data.append("phoneNumber", formData.phoneNumber);
      data.append("message", formData.message);
      data.append("resume", formData.resume); // Append resume to form data

      // Log the form data being sent
      console.log("Form Data:", {
        name: formData.name,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        message: formData.message,
        resume: formData.resume,
      });

      await axios.post("https://ews-server.onrender.com/upload", data);

      setIsSubmitted(true);
      setTimeout(() => {
        setIsSubmitted(false);
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          message: "",
          resume: null,
        });
      }, 3000);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <SEO
        title="Contact - Empower Workforce Solutions"
        description="Get in touch with Empower Workforce Solutions to learn more about our services and how we can help you."
      />
      <ContactContainer>
        <h2>Contact Us</h2>
        {isSubmitted ? (
          <SuccessMessage>
            <AiOutlineCheckCircle size={50} />
            <p>Message sent successfully! We will reach out to you soon.</p>
          </SuccessMessage>
        ) : (
          <ContactForm onSubmit={handleSubmit} encType="multipart/form-data">
            <FormField>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
            </FormField>
            <FormField>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
            </FormField>
            <FormField>
              <input
                type="tel"
                name="phoneNumber"
                placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
              {errors.phoneNumber && (
                <ErrorMessage>{errors.phoneNumber}</ErrorMessage>
              )}
            </FormField>
            <FormField>
              <textarea
                name="message"
                placeholder="I am looking for . . .for ex:FullStackDeveloper"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
              {errors.message && <ErrorMessage>{errors.message}</ErrorMessage>}
            </FormField>
            <FormField>
              <ResumeLabel htmlFor="resume">Upload Your Resume</ResumeLabel>
              <input
                type="file"
                id="resume"
                name="resume"
                onChange={handleChange}
                accept=".pdf,.doc,.docx"
              />
              {errors.resume && <ErrorMessage>{errors.resume}</ErrorMessage>}
            </FormField>
            {isSubmitting ? (
              <SubmissionWrapper>
                <LoadingSpinner />
                <p>Submitting your message...</p>
              </SubmissionWrapper>
            ) : (
              <Button type="submit">Submit</Button>
            )}
          </ContactForm>
        )}
      </ContactContainer>
    </>
  );
};

export default Contact;
